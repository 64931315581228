.resources-filters {
  @apply border-b border-neutral-200 text-gray-600;
}

.resources-filters__group {
  @apply py-2 px-4;

  @apply font-heading;

  @apply border-t border-neutral-200;
  @apply bg-neutral-100;
}


.gl_checkbox {
  @apply mr-1;
  opacity: 0.7;
}
.gl_checkbox:checked {
  opacity: 1;
}