.wheel svg {
  margin: auto;
}

.wheel p {
  line-height: 1.5em;
  width: 720px;
}

.wheel a {
  color: steelblue;
}

.wheel a:not(:hover) {
  text-decoration: none;
}

.wheel text {
  font: 400 8px "Helvetica Neue";
}

#circle circle {
  fill: none;
  pointer-events: all;
}

.wheel .group path {
  fill-opacity: 0.7;
}

.wheel path.chord {
  stroke: #000;
  stroke-width: 0.25px;
  fill-opacity: 0.85;
}

#circle:hover path.fade,
#circle:hover .group.fade path,
#circle:hover g.fade {
  fill-opacity: 0.1;
  stroke: none;
}

#circle:hover path.show {
  fill: #000;
}

@media (min-width: theme("screens.md")) {
  .wheel text {
    font-size: 14px;
  }
}
