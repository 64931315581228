@import "~@fortawesome/fontawesome-free/css/fontawesome.css";
@import "~@fortawesome/fontawesome-free/css/regular.css";
@import "~@fortawesome/fontawesome-free/css/solid.css";

@tailwind base;

@layer base {
  h1 {
    @apply text-lg;
  }
  h2 {
    @apply text-md font-semibold;
  }
}

@tailwind components;
@tailwind utilities;
@tailwind plugins;

.resource-box {
  @apply shadow-md p-3 mb-5 border-t border-l border-neutral-200;
}

.resource-sib {
  /*
  -webkit-box-shadow: 0px 0px 10px 0px rgb(255, 0, 0);
  box-shadow: 0px 0px 10px 0px rgba(255, 18, 18, 0.7);
  */
}

.custom-scrollbar {
  @apply scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 scrollbar-thin;
}

body {
  font-family: Source Sans pro, Arial, sans-serif;
}

@media (min-width: theme("screens.3xl")) {
  .max-container {
    max-width: 1900px;
  }
}

.timeline-InformationCircle-widgetParent {
  display: none !important;
}

.SandboxRoot.env-bp-550 .timeline-Tweet-text {
  font-size: 1rem !important;
}

@media (min-width: theme("screens.md")) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}
