.node {
  cursor: pointer;
}

.node:hover {
  stroke: #000;
  stroke-width: 1.5px;
}

.node--leaf {
  fill: white;
}

.label {
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-anchor: middle;
  font-weight: bold;
  cursor: pointer;
  text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
}

.description {
  font: 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-anchor: middle;
}

.node--root {
  pointer-events: none;
}

@media (min-width: theme("screens.md")) {
  .label {
    font-size: 15px;
  }
}
@media (min-width: theme("screens.lg")) {
  .description {
    font-size: 18px;
  }
  .label {
    font-size: 25px;
  }
}
